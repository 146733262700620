<template>
  <div class="flex flex-col">
    <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
      <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
        <div
          v-if="this.sortedReplies().length > 0"
          class="shadow overflow-hidden border-b border-gray-200 sm:"
        >
          <SurveyRepliesTable
            :key="currentPage"
            :tablePaginatedData="sortedReplies()"
            :to="to"
            :from="from"
            :total="total"
            :lastPage="lastPage"
            @firstPage="firstPage"
            @previousPage="previousPage"
            @nextPage="nextPage"
            @lastPage="goLastPage"
            @sorting="sort($event)"
            :page="page"
          />
        </div>
        <div v-else class="text-center mt-12">
          <svg
            class="mx-auto h-16 w-16 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vector-effect="non-scaling-stroke"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
            />
          </svg>
          <h3 class="mt-2 text-xl font-medium text-gray-900">No Survey</h3>
          <p class="mt-1 text-base text-gray-500">
            You don't have any survey at the moment.
          </p>
        </div>
      </div>
    </div>
  </div>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
import axios from "axios";
import SurveyRepliesTable from "../../components/surveys/SurveyRepliesTable.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  title() {
    return `Surveys - ${localStorage.getItem("title")}`;
  },
  props: ["page"],
  components: {
    SurveyRepliesTable,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      currentSort: "score",
      currentSortDir: "asc",
      surveyReplies: [],
      currentPage: 1,
      to: "",
      from: "",
      total: "",
      lastPage: "",
    };
  },
  methods: {
    async getSurveyReplies() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/replyToSurveyRequests?page=${this.page}`
        );
        if (res) {
          this.to = res.data.to;
          this.from = res.data.from;
          this.total = res.data.total;
          this.lastPage = res.data.last_page;
          this.surveyReplies = res.data.data;
          this.isLoading = false;
        }
      } catch (error) {
        console.error(error);
          this.isLoading = false;
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
      }
    },
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    sortedReplies() {
      return this.surveyReplies.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    firstPage() {
      let firstPage = 1;
      this.currentPage = firstPage;

      this.$router.push(`/reply-surveys/${firstPage}`);
    },
    nextPage() {
      let nextPage = parseInt(this.page) + 1;
      this.currentPage = nextPage;
      this.$router.push(`/reply-surveys/${nextPage}`);
      this.$forceUpdate();
    },
    previousPage() {
      let previousPage = null;
      if (this.page > 1) {
        previousPage = parseInt(this.page) - 1;
      } else {
        previousPage = parseInt(this.page);
      }
      this.currentPage = previousPage;
      this.$router.push(`/reply-surveys/${previousPage}`);
      this.$forceUpdate();
    },
    goLastPage() {
      this.currentPage = this.lastPage;
      this.$router.push(`/reply-surveys/${this.lastPage}`);
      this.$forceUpdate();
    },
  },
  mounted() {
    this.getSurveyReplies();
  },
};
</script>

<style></style>
